.newUser {
	flex: 4;
	padding: 20px;
}

.newUserTitle {
	color: coral;
}

.newUserForm {
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex-wrap: wrap;
	max-width: 400px;
}

.newUserErrorMessage {
	color: coral;
	padding-top: 20px;
	font-weight: 600;
}
