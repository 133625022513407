.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	padding: 20px;
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.summPlannedRepay {
	margin: 5px 0 10px;
	color: rgb(39, 39, 39);
}

.inputWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
