.itemsList {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 10px 0;
}

.item {
	padding: 10px;
	border: 1px solid rgba(224, 224, 224, 1);
	border-radius: 5px;
	cursor: pointer;
}

.item:not(:first-child) {
	margin-left: 10px;
}

.item:not(.currentItem):hover {
	background-color: rgba(224, 224, 224, 0.8);
}

.currentItem {
	background-color: rgb(101, 185, 185);
	border-color: rgb(101, 185, 185);
}
