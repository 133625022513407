.table {
	/* max-width: 700px; */
}

.table td {
	padding: 3px;
}

@media (max-width: 1280px) {
	.table {
		/* max-width: 700px; */
		font-size: 0.85em;
	}
}
