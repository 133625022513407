.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	padding: 20px;
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
