.dataList {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;
	width: auto;
	list-style-type: none;
}

.dataList li {
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: center;
	border: 1px solid teal;
	padding: 15px;
}

@media (max-width: 1280px) {
	.dataList {
		gap: 10px;
	}
}
