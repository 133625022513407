.wrapper {
	width: auto;
}
.buttonsGroup {
	display: flex;
	align-content: center;
	gap: 50px;
}

@media (max-width: 1281px) {
	.table {
		font-size: 0.8rem;
	}
}
