.wrapper {
	width: auto;
	padding: 0 5px 50px;
}

.dataPickerWrapper {
	display: flex;
	gap: 10px;
	align-items: flex-end;
}

.dataPickerWrapper button {
	height: 55px;
	width: 150px;
}

/* .dataList {
	display: flex;
	gap: 60px;
	flex-wrap: wrap;
	width: 100%;
	list-style-type: none;
}

.dataList li {
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-align: center;
	border: 1px solid teal;
	padding: 15px;
} */

@media (max-width: 1000px) {
	.wrapper {
		width: 100%;
	}
}
