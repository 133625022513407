.wrapper {
	margin-left: 20px;
	align-self: center;
}

.restOrgLimit {
	margin: 0;
}

.restText {
	display: block;
	margin-bottom: 10px;
}

.restLimitPositive {
	color: rgb(58, 136, 58);
}

.restLimitNegative {
	color: rgb(168, 68, 68);
}
