.wrapper {
	width: 100%;
	padding: 0 5px;
}

.btnPanel {
	display: flex;
	margin-bottom: 10px;
}

.circleButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 20px;
}

.restDaysCellPositive {
	background-color: rgb(178, 255, 178);
}

.restDaysCellNegative {
	background-color: rgb(255, 194, 194);
}

@media (max-width: 1281px) {
	.table {
		font-size: 0.8rem;
	}
}
